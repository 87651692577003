import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';

export default function TestFeed() {
  return (
    <React.Fragment>
      <CssBaseline />
      <AppBar position="relative">
        <Toolbar>
          <Typography variant="h6" color="inherit" noWrap>
            Acumin Test Site
          </Typography>
        </Toolbar>
      </AppBar>
      <div style={{ padding: 20 }}>
        <Grid container spacing={2} justify="center">
          <Grid item>
            <Button color="primary" variant="contained">
                This button!
            </Button>
          </Grid>
          <Grid item>
            <Button variant="contained" color="secondary">
              That button!
            </Button>
          </Grid>
          <Grid item>
            <Button color="default" variant="outlined">
              Another button!
            </Button>
          </Grid>
          <Grid item>
            <Button className="testButton" variant="contained">
              Another button!
            </Button>
          </Grid>
        </Grid>
      </div>
    </React.Fragment>
  );
}