import React from 'react'
import { createMuiTheme, ThemeProvider, makeStyles } from '@material-ui/core'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardActions from '@material-ui/core/CardActions';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FavoriteIcon from '@material-ui/icons/Favorite';
import ShareIcon from '@material-ui/icons/Share';
import TwitterIcon from '@material-ui/icons/Twitter'
import FbIcon from '@material-ui/icons/Facebook'
import Box from '@material-ui/core/Box';
import * as acuPalette from '../../variables/palette'
import RatingsContainer from '../containers/ratingsContainer';
import Chip from '@material-ui/core/Chip';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme) => ({
    card: {
      minWidth: 250,
      position: 'relative',
      boxShadow: '0 8px 24px 0 rgba(0,0,0,0.12)',
      overflow: 'visible',
      borderRadius: '1.5rem',
      '&:before': {
        content: '""',
        position: 'absolute',
        zIndex: 0,
        display: 'block',
        width: '100%',
        bottom: -1,
        height: '100%',
        borderRadius: '1.5rem',
      },
    },
    main: {
      overflow: 'hidden',
      borderTopLeftRadius: '1.5rem',
      borderTopRightRadius: '1.5rem',
      zIndex: 1,
      '&:after': {
        content: '""',
        position: 'absolute',
        bottom: 0,
        display: 'block',
        width: '100%',
        height: '100%',
        background: 'linear-gradient(to top, ' + acuPalette.grayDark + ', rgba(0,0,0,0))',
      },
    },
    content: {
      position: 'absolute',
      bottom: 0,
      width: '100%',
      zIndex: 1,
      padding: '1.5rem 1.5rem 1rem',
    },
    avatar: {
      width: 48,
      height: 48,
      backgroundColor: acuPalette.complementaryLight,
    },
    tag: {
      backgroundColor: acuPalette.triadicPurple,
      marginRight: '5px',
    },
    title: {
      fontFamily: "'Sen', sans-serif",
      fontSize: '2rem',
      fontWeight: 800,
      color: '#fff',
    },
    author: {
      zIndex: 1,
      position: 'relative',
      borderBottomLeftRadius: '1.5rem',
      borderBottomRightRadius: '1.5rem',
    },
    media: {
        height: 0,
        paddingTop: '100%',
    },
    iconButton: {
        color: acuPalette.light
    },
    accordionFlat: {
      background: theme.palette.background.paper,
      boxShadow: 'none',
      '&:before': {
        display: 'none',
      },
      '&$expanded': {
        margin: 'auto',
      },
    },
  }));


export const ExampleCard = React.memo(function ExampleCard() {
  const styles = useStyles();
  return (
    <>
      <Card className={styles.card}>
        <Box className={styles.main} minHeight={300} position={'relative'}>
          <CardMedia
            className={styles.media}
            image={
              'https://source.unsplash.com/random'
            }
          />
          <div className={styles.content}>
            <Typography variant={'h2'} className={styles.title}>
              Some Title Stuff Here
            </Typography>
            <Chip size="small" label="Tag" className={styles.tag}/>
            <Chip size="small" label="Tag" className={styles.tag}/>
          </div>
        </Box>
        <RatingsContainer/>
        <Accordion square className={styles.accordionFlat}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <CardHeader
            avatar={
              <Avatar aria-label="news outlet" className={styles.avatar}>
                N
              </Avatar>
            }
            title="Publisher Name"
            subheader="March 30, 2021 | 5 Min Read"
            />
          </AccordionSummary>
          <AccordionDetails>
          <CardHeader
            avatar={
              <Box position="relative" display="inline-flex">
              <CircularProgress variant="determinate" value={78} />
              <Box
                top={0}
                left={0}
                bottom={0}
                right={0}
                position="absolute"
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <Typography variant="caption" component="div" color="textSecondary">78%
                </Typography>
              </Box>
            </Box>
            }
            ></CardHeader>
          
            <Typography>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex,
              sit amet blandit leo lobortis eget.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <CardActions>
              <IconButton aria-label="add to favorites" className={styles.iconButton}>
                <FavoriteIcon />
              </IconButton>
              <IconButton aria-label="share" className={styles.iconButton}>
                <TwitterIcon />
              </IconButton>
              <IconButton aria-label="share" className={styles.iconButton}>
                <FbIcon />
              </IconButton>
              <IconButton aria-label="share" className={styles.iconButton}>
                <ShareIcon />
              </IconButton>
            </CardActions>
      </Card>
    </>
  );
});
export default ExampleCard