import React from 'react'
import Grid from '@material-ui/core/Grid';

// Single customization point for a news grid container

export default function NewsGrid({ children }) {
    return (
        <div style={{ padding: 20 }}>
            <Grid container spacing={8}>
                {children}
            </Grid>
        </div>
    )
  }