import React from 'react'
import Grid from '@material-ui/core/Grid';

// Single customization point for a news grid item

export default function NewsGridItem({ children }) {
    return (
        <Grid item lg={4} sm={6} xs={12}>
            {children}
        </Grid> 
    )
  }