export const primary = '#52796f',
light = '#84a98c',
lighter = '#cad2c5',
dark = '#354f52',
darker = '#2f3e46',
complementary = '#79525c',
complementaryLight = '#a97f89',
complementaryDark = '#4c2933',
analogousGreen = '#52795c',
analogousGreenLight = '#7fa889',
analogousGreenDark = '#274d32',
analogousBlue = '#526f79',
analogousBlueLight = '#7f9da8',
analogousBlueDark = '#27444d',
triadicBlue = '#525c79',
triadicBlueLight = '#7f89a8',
triadicBlueDark = '#28334d',
triadicPurple = '#6f5279',
triadicPurpleLight = '#9e7fa8',
triadicPurpleDark = '#43294d',
white = '#ffffff',
gray = '#424242',
grayDark = '#303030',
black = '#000000'