import React from 'react'
import { createMuiTheme, ThemeProvider, makeStyles } from '@material-ui/core'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import FavoriteIcon from '@material-ui/icons/Favorite';
import ShareIcon from '@material-ui/icons/Share';
import TwitterIcon from '@material-ui/icons/Twitter'
import FbIcon from '@material-ui/icons/Facebook'
import MoreVertIcon from '@material-ui/icons/MoreVert';
import * as acuPalette from '../../variables/palette'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    borderRadius: '15px',
    boxShadow:  '-6.91px 6.91px 12px #2B2B2B, 6.91px -6.91px 12px #353535',
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  avatar: {
    backgroundColor: acuPalette.complementaryLight,
  },
  iconButton: {
    color: acuPalette.light,
    background: 'linear-gradient(225deg, #333333, #2b2b2b)',
    boxShadow: '-5px 5px 10px #252525, 5px -5px 10px #3b3b3b',
    marginRight: '10px'
  }
}));


export default function NewsCardNeumorphic({ children }) {
  const classes = useStyles();

  return (
        <Card className={classes.root}>

          <CardHeader
            avatar={
              <Avatar aria-label="news outlet" className={classes.avatar}>
                N
              </Avatar>
            }
            action={
              <IconButton aria-label="settings" className={classes.iconButton}>
                <MoreVertIcon />
              </IconButton>
            }
            title="Publisher Name"
            subheader="March 30, 2021"
          />

          <CardMedia
            className={classes.media}
            image="https://source.unsplash.com/random"
            title="Random Image"
          />

          <CardContent>
            <Typography gutterBottom variant="h6" component="h2">
                Title Goes Here
            </Typography>
            <Typography variant="body2" component="p">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus sagittis, velit ac 
              elementum convallis, dui neque luctus massa, ac congue est nunc id erat. Mauris sit amet 
              tellus vitae erat feugiat mollis sit amet eget nunc.
            </Typography>
          </CardContent>

          <CardActions style={{justifyContent: 'center', paddingBottom: '20px'}}>
            <IconButton aria-label="add to favorites" className={classes.iconButton}>
              <FavoriteIcon />
            </IconButton>
            <IconButton aria-label="share" className={classes.iconButton}>
              <TwitterIcon />
            </IconButton>
            <IconButton aria-label="share" className={classes.iconButton}>
              <FbIcon />
            </IconButton>
            <IconButton aria-label="share" className={classes.iconButton}>
              <ShareIcon />
            </IconButton>
          </CardActions>

        </Card>
  )
}