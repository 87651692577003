import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/core/styles';
import { createMuiTheme } from '@material-ui/core/styles';

// A custom theme for this app,  global changes can be made here.
const acu_web_theme = createMuiTheme({
    palette: {
        type: 'dark',
        primary: {
            main: '#52796f',
            dark: '#354F52'
        },
        secondary: {
          main: '#79525c',
        },
    },
});

export default function AcuWeb(props) {
  return (
    <React.Fragment>
      <Helmet>
        <meta name="viewport" content="minimum-scale=1, initial-scale=1, width=device-width" />
        <link
          href="https://fonts.googleapis.com/css?family=Roboto:400,500,700&display=swap"
          rel="stylesheet"
        />
      </Helmet>
      <ThemeProvider theme={acu_web_theme}>
        <CssBaseline />
        {props.children}
      </ThemeProvider>
    </React.Fragment>
  );
}

AcuWeb.propTypes = {
  children: PropTypes.node,
};