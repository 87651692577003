import React from "react"
import CssBaseline from '@material-ui/core/CssBaseline'
import Container from '@material-ui/core/Container'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import NewsCard from "../../plugins/acu-web-theme/js/components/cards/newsCard"
import NewsGrid from "../../plugins/acu-web-theme/js/components/newsGrid"
import NewsGridItem from "../../plugins/acu-web-theme/js/components/newsGridItem"
import ExampleCard from "../../plugins/acu-web-theme/js/components/cards/exampleCard"
import NewsCardNeumorphic from "../../plugins/acu-web-theme/js/components/cards/newsCardNeumorphic"


const TestPage = (props) => (
    
    <React.Fragment>
        <CssBaseline />
        <AppBar position="relative">
        <Toolbar>
          <Typography variant="h6" color="inherit" noWrap>
            Acumin Test Site
          </Typography>
        </Toolbar>
      </AppBar>
        <Container maxWidth="lg"> 
            <NewsGrid>

                <NewsGridItem>
                    <NewsCardNeumorphic>

                    </NewsCardNeumorphic>
                </NewsGridItem>

                <NewsGridItem>
                    <NewsCardNeumorphic>
                        
                    </NewsCardNeumorphic>
                </NewsGridItem>
                
                <NewsGridItem>
                    <NewsCardNeumorphic>
                        
                    </NewsCardNeumorphic>
                </NewsGridItem>

                <NewsGridItem>
                    <ExampleCard>
                        
                    </ExampleCard>
                </NewsGridItem>

                <NewsGridItem>
                    <ExampleCard>
                        
                    </ExampleCard>
                </NewsGridItem>

                <NewsGridItem>
                    <ExampleCard>
                        
                    </ExampleCard>
                </NewsGridItem>

            </NewsGrid>
        </Container>
    </React.Fragment>

)

export default TestPage;